/* Web Font */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap'); */

/* Quicksand 400? 500 600 700 */
@font-face {
  font-family: QuicksandLight;
  font-weight: 400;
  font-display: fallback;
  src: url('../assets/font/quicksand-v28-latin-regular.woff2');
}
@font-face {
  font-family: Quicksand;
  font-weight: 500;
  font-display: fallback;
  src: url('../assets/font/quicksand-v28-latin-500.woff2');
}
@font-face {
  font-family: QuicksandSemiBold;
  font-weight: 600;
  font-display: fallback;
  src: url('../assets/font/quicksand-v28-latin-600.woff2');
}
@font-face {
  font-family: QuicksandBold;
  font-weight: 700;
  font-display: fallback;
  src: url('../assets/font/quicksand-v28-latin-700.woff2');
}

/* Poppins 400, 600 */
@font-face {
  font-family: Poppins;
  font-weight: 400;
  font-display: fallback;
  src: url('../assets/font/poppins-v19-latin-regular.woff2');
}
@font-face {
  font-family: PoppinsBold;
  font-weight: 600;
  font-display: fallback;
  src: url('../assets/font/poppins-v19-latin-600.woff2');
}

input {
  font-family: Quicksand, Helvetica, Arial, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

h1 {
  font-family: PoppinsBold, Helvetica, Arial, sans-serif;
  font-size: 65px !important;
  line-height: 1.3;
  letter-spacing: 0;
}

h2 {
  font-family: PoppinsBold, Helvetica, Arial, sans-serif;
  font-size: 40px !important;
  line-height: 1.3;
  font-weight: 600;
  letter-spacing: 0;
}

h3 {
  font-size: 27px !important;
  line-height: 27px;
  letter-spacing: 0.5px;
  font-family: PoppinsBold, Helvetica, Arial, sans-serif;
}

h4 {
  font-size: 21px !important;
  line-height: 29px;
  letter-spacing: 0.5px;
  font-family: PoppinsBold, Helvetica, Arial, sans-serif;
}

h5 {
  font-size: 18px !important;
  line-height: 25px;
  letter-spacing: 0.85px;
  font-family: PoppinsBold, Helvetica, Arial, sans-serif;
}

h6 {
  font-size: 11px !important;
  line-height: 16px;
  font-family: PoppinsBold, Helvetica, Arial, sans-serif;
}

button {
  font-family: PoppinsBold, Helvetica, Arial, sans-serif;
}

p {
  font-size: 18px;
  line-height: 1.5;
  font-family: Quicksand, Helvetica, Arial, sans-serif;
}

li {
  font-size: 18px;
  font-family: Quicksand, Helvetica, Arial, sans-serif;
}

strong,b {
  font-family: QuicksandBold, Helvetica, Arial, sans-serif;
}

strong.quicksand {
  font-family: QuicksandBold, Helvetica, Arial, sans-serif;
}

strong.poppins {
  font-family: PoppinsBold, Helvetica, Arial, sans-serif;
}

@media screen and (max-width: 991px) {
  h1 {
    font-size: 42px !important;
  }

  h2 {
    font-size: 28px !important;
  }

  h3 {
    font-size: 22px !important;
  }

  p,
  li {
    font-size: 16px !important;
  }
}