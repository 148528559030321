@import './fonts.css';
@import 'animate.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}
body {
  font-family: Quicksand, Helvetica, Arial, sans-serif !important;
  font-size: 16px;
  font-weight: 500;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
}
button {
  background-color: transparent;
  border: none;
}
.App {
  text-align: center;
}
a {
  text-decoration: none !important;
}


/****** NavBar ******/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32 ease-in-out;
}
nav.navbar.scrolled {
  padding: 0;
  background-color: #121212;
}
nav.navbar a.navbar-brand {
  width: 9%; /* logo */
}
nav.navbar a.navbar-brand img {
  width: 100%;
  height: auto;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}
nav.navbar .navbar-nav .nav-link.navbar-link:hover,
nav.navbar .navbar-nav .nav-link.navbar-link:active {
  opacity: 1;
}
span.navbar-text {
  display: flex;
  align-items: center;
}
.social-icon {
  display: inline-block;
  margin-left: 14px;
}
.social-icon a {
  width: 42px;
  height: 42px;
  background-color: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
  transform: scale(1);
}
.social-icon a svg {
  width: 100%;
  height: auto;
  z-index: 1;
  transition: 0.3s ease-in-out;
  fill: #fff;
}
.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text .email {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text .email span {
  z-index: 1;
}
.navbar-text .email::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text .email:hover {
  color: #121212;
}
.navbar-text .email:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}
@media screen and (max-width: 990px) {
  .navbar {
    background-color: #121212;
  }
  .navbar-text {
    justify-content: center;
  }
  nav.navbar a.navbar-brand {
    width: 15%; /* logo */
  }
}


/****** Banner ******/
.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
  /* background-image: url('./assets/img/banner-bg.png'); */
  /* background-position: top center;
  background-size: cover;
  background-repeat: no-repeat; */
  margin-top: 25px;
  font-size: 21px;
  text-align: center;

  -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadein 3s; /* Firefox < 16 */
      -ms-animation: fadein 3s; /* Internet Explorer */
       -o-animation: fadein 3s; /* Opera < 12.1 */
          animation: fadein 3s;
}
@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}
.banner h1 {
  min-height: 130px;
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.banner p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner .email {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner .email:hover {
  color: #fff;
}
.banner a svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner a:hover svg {
  margin-left: 25px;
  color: #fff;
}
.banner img {
  animation: updown 3s linear infinite;
}
@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}
.banner .img-credit{
  margin-top: 20px;
  font-size: 10px;
  text-align: end;
  color: #464646;
}
.banner .img-credit a{
  color: #464646;
}
@media screen and (max-width: 768px) { 
  .banner img {
    margin-top: 50px;
  }
}


/****** Skills ******/
.skill {
  padding: 0 0 50px 0;
  position:relative;
}
.skill-bx {
  background: #151515;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
}
.skill h2 {
  font-size: 45px;
  font-weight: 700;
}
.skill p {
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;
}
.skill-slider {
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.skill-slider .item {
  width: 100%;
  height: auto;
  margin: 0 auto 15px;
  font-size: 100px;
}
.background-image-left {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}


/****** Projects ******/
.project {
  padding: 80px 0;
  position: relative;
  background-color: black;
}
.project h2 {
	font-size: 45px;
	font-weight: 700;
  text-align: center;
}
.project p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}
.project a {
  color: #4A2FBD;
}
.project .nav.nav-pills {
  width: 72%;
  margin: 20px auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.project .nav.nav-pills .nav-item {
  width: 50%;
}
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}
.second-tab-achievement-list {
  width: 80%;
  margin: 0 auto;
  text-align: left;
}
.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
  height: 500px;
}
.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  opacity: 0.85;
  position: absolute; /* to make bg color overlap the image */
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-imgbx img {
  border-radius: 30px;
  height: 500px;
  margin-left: -45%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
.proj-icon {
  margin: 0 5px;
  font-size: 40px !important;
}
.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}


/****** History ******/
.history {
  padding: 80px 0;
}
.history h2 {
  font-size: 45px;
  font-weight: 700;
}
.timeline {
  margin: 0 auto;
  width:100%;
  position: relative;
}
.timeline::before {
  content: '';
  position: absolute;
  top: 0;
  left:calc(33% + -1px);
  bottom: 0;
  border-left: 1px dashed #b8b8b8;
}
.timeline::after {
  content: "";
  display: table;
  clear: both;
}
.timeline .content{
  clear: both;
  text-align: left;
  position: relative;
}
.timeline .year {
  margin-bottom: .5em;
  float: left;
  width: 33%;
  padding-right: 60px;
  text-align: right;
  position: relative;
}
.timeline .year::before {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  border: none;
  background: linear-gradient(90.21deg, rgb(170, 54, 124) -5.91%, rgb(74, 47, 189) 111.58%);
  border-radius: 100%;
  top: 30%;
  right: -7px;
  z-index: 99;
}
.timeline .description {
  white-space: pre-wrap;
  margin: 0 0 4em;
  float: right;
  width: 66%;
  padding-left: 50px;
}
.timeline .description div {
  color: #b8b8b8;
}
@media screen and (max-width: 768px) {
  .timeline::before {
    left: calc(10% + 2px);
  }
  .timeline .year {
    font-size: 30px;
    width: 100% !important;
    text-align: left !important;
    padding-left: 15% !important;
  }
  .timeline .year::before {
    left: 9%;
  }
  .timeline .description {
    padding-left: 15% !important;
    width: 100%;
    padding-left: 0;
  }
}


/****** Footer ******/
.footer {
  padding: 30px 0 0;
}
.footer img {
  width: 26%;
  margin-bottom: 10px;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}